import React from "react"

import SEO from "@components/common/seo"
import Image from "@components/common/image"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <div className="not-found">
    <SEO title="404 Not found" />
    <div className="header-logo">
      <Image src="al-falaj-logo.png" />
    </div>
    <div className="content-wrapper">
      <Image src="not-found.png" />
      <div className="content-container">
        <p>
          Oops! Looks like our communication is broken.<br/>
          Don’t lose hope and try again, success is just around the corner.
        </p>
        <Link to="/" className="btn btn-outline-gray">
          Go back to home
        </Link>
      </div>
    </div>
  </div>
)

export default NotFoundPage
